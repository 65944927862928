    // SearchForm.js
    import React, { useState , useEffect } from "react";
    import "./SearchForm.css";
    import industriesLists from "./industriesList";

    const SearchForm = ({
    searchBar,
    searchHandler,
    category,
    isChecked,
    setIsChecked,
    onIncludeExcludeChange,
    onIndustryChange,
    onYearChange,
    resetDropdowns,
    SetResetDropdowns
    }) => {
    const [isYearDropdownVisible, setYearDropdownVisible] = useState(false);
    const [isOptionDropdownVisible, setOptionDropdownVisible] = useState(false);
    const [isIndustryDropdownVisible, setIndustryDropdownVisible] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedIndustry, setSelectedIndustry] = useState("Search--By--Industry");

    const toggleYearDropdown = () => {
        setYearDropdownVisible(!isYearDropdownVisible);
        setOptionDropdownVisible(false); // Close the option dropdown
        setIndustryDropdownVisible(false); // Close the industry dropdown
    };

    const toggleOptionDropdown = (e) => {
        e.stopPropagation(); // Stop event propagation to prevent the year or industry dropdown from opening
        setOptionDropdownVisible(!isOptionDropdownVisible);
        setYearDropdownVisible(false); // Close the year dropdown
        setIndustryDropdownVisible(false); // Close the industry dropdown   
    };

    const toggleIndustryDropdown = (e) => {
        e.stopPropagation(); // Stop event propagation to prevent the year or option dropdown from opening
        setIndustryDropdownVisible(!isIndustryDropdownVisible);
        setYearDropdownVisible(false); // Close the year dropdown
        setOptionDropdownVisible(false); // Close the option dropdown
    };

    const handleYearSelect = (year) => {
        setSelectedYear(year);
        setYearDropdownVisible(false); // Close the year dropdown after selecting a year
        onYearChange(year);
    };

    const handleOptionSelect = (option) => {
        setIsChecked(option === "Include");
        setOptionDropdownVisible(false); // Close the option dropdown after selecting an option
        onIncludeExcludeChange(option);
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const handleIndustrySelect = (industry) => {
            const maxLength = window.innerWidth < 668 ? 7 : industry.length;
        
            // Truncate the text accordingly
            setSelectedIndustry(truncateText(industry, maxLength));
        
            // Close the industry dropdown after selecting an industry
            setIndustryDropdownVisible(false);
        
            // Call the parent handler with the selected industry
            onIndustryChange(industry);
        };

        useEffect(() => {
            const handleResize = () => {
            // Update state based on window width
            if (window.innerWidth >= 768) {
                setSelectedIndustry("Search--By--Industry");
            } else {
                setSelectedIndustry("Industry");
            }
            };
        
            // Initial call to set the state based on the initial window width
            handleResize();
        
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);
        
            // Clean up the event listener when the component is unmounted
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        }, []); 

        useEffect(() => {
            if (resetDropdowns) {
              // Reset the dropdowns' state
                setYearDropdownVisible(false);
                setOptionDropdownVisible(false);
                setIndustryDropdownVisible(false);
                setSelectedYear(null);
                setSelectedIndustry("Search--By--Industry");
            
                // After resetting, update the parent to indicate reset is complete
                SetResetDropdowns(false);
                }
            }, [resetDropdowns, SetResetDropdowns]);


    return (
        <div className="text-sm ">
        <div className="flex justify-end gap-2 mx-4 my-2 md:text-base">
            {category === "t" && (
            <div className="dropdown relative inline-block text-left">
                {/* <button
                id="optionDropdownButton"
                type="button"
                onClick={toggleOptionDropdown}
                className="dropdown__button px-2 py-1 md:px-[2rem] lg:px-[2rem]  text-[#fff] text-center flex text-[16px] md:text-[18px] lg:text-[18px] xl:text-[18px] border w-[70px] md:w-[120px] lg:w-[120px] xl:w-[120px] border-[#fff] rounded-md hover:bg-gray-100 focus:outline-none"
                >
                {isChecked ? "Include" : "Exclude"}
                <svg
                    className="w-4 h-4 ms-2 mt-1.5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    fillRule="evenodd"
                    d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6l-6 6z"
                    clipRule="evenodd"
                    />
                </svg>
                </button> */}
                <div
                className={`dropdown__items w-[70px]  md:w-[120px] lg:w-[120px] xl:w-[120px] ${
                    isOptionDropdownVisible ? "dropdown--show" : "dropdown--hide"
                } origin-top-right absolute  mt-2 w-22  rounded-md shadow-lg z-40 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                <div
                    className="py-1 pl-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="optionDropdownButton"
                >
                    <button
                    onClick={() => handleOptionSelect("Include")}
                    className="block px-1 md:px-6 lg:px-6 xl:px-6 py-2 text-sm text-[#fff] hover:bg-gray-100 hover:rounded-md hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    >
                    Include
                    </button>
                    <button
                    onClick={() => handleOptionSelect("Exclude")}
                    className="block  px-1 md:px-6 lg:px-6 xl:px-6 py-2 text-sm text-[#fff] hover:bg-gray-100 hover:rounded-md hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    >
                    Exclude
                    </button>
                </div>
                </div>
            </div>
            )}

            <div className="dropdown relative inline-block text-center"> {/* Search by industry */}
                <button
                    id="industryDropdownButton"
                    type="button"
                    onClick={toggleIndustryDropdown}
                    className="dropdown__button p-1 text-[#fff] text-center justify-center  flex text-[18px] border w-[120px]  lg:w-[280px] md:w-[280px] border-[#fff] rounded-md hover:bg-gray-100 focus:outline-none"
                >
                    {selectedIndustry}
                    <svg
                    className="w-4 h-4 ms-2 mt-1.5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6l-6 6z"
                        clipRule="evenodd"
                    />
                    </svg>
                </button>
            <div
                className={`industry_dropdown__items ${
                isIndustryDropdownVisible ? "dropdown--show" : "dropdown--hide"
                } origin-top-right absolute  mt-2 md:w-[280px] lg:w-[280px] rounded-md shadow-lg z-40 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
            >
                <div
                className="py-1 pl-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="industryDropdownButton"
                >
                {industriesLists.map((industry) => (
                    <button key={industry.id}
                    onClick={() => handleIndustrySelect(`${industry.industryName}`)}
                    className="block px-2 py-2 w-full text-sm text-[#fff] hover:bg-gray-100 hover:rounded-md hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    >
                        {industry.industryName}
                    </button>
                ))}
                
                </div>
            </div>
            </div>

            <div className="dropdown relative inline-block text-left">
                <button
                    id="yearDropdownButton"
                    type="button"
                    onClick={toggleYearDropdown}
                    className="dropdown__button px-2 py-1 md:px-[2rem] lg:px-[2rem]  text-[#fff] text-center flex text-[16px] md:text-[18px] lg:text-[18px] xl:text-[18px] border w-[70px] md:w-[120px] lg:w-[120px] xl:w-[120px] border-[#fff] rounded-md hover:bg-gray-100 focus:outline-none"
                >
                    {selectedYear || "Year"}
                    <svg
                    className="w-4 h-4 ms-2 mt-1.5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6l-6 6z"
                        clipRule="evenodd"
                    />
                    </svg>
                </button>
                <div
                    className={`dropdown__items w-[70px]  md:w-[120px] lg:w-[120px] xl:w-[120px]  ${
                    isYearDropdownVisible ? "dropdown--show" : "dropdown--hide"
                    } origin-top-right absolute mt-2 w-22 rounded-md shadow-lg z-40 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                    <div
                    className="py-1 pl-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="yearDropdownButton"
                    >
                    {[...Array(11)].map((_, index) => {
                        const year = 2013 + index;
                        return (
                        <button
                            key={year}
                            onClick={() => handleYearSelect(year)}
                            className="block px-1 md:px-6 lg:px-6 xl:px-6 py-2 md:ml-1 lg:ml-1 xl:ml-1 text-sm text-[#fff] hover:rounded-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                        >
                            {year}
                        </button>
                        );
                    })}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
    };

    export default SearchForm;
