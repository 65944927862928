    // CategoryRadioButtons.js
    import React from "react";

    const CategoryRadioButtons = ({ category, toggleTableHandler }) => {
    return (
        <div className="mt-8 btn-container w-[248px] md:w-fit lg:w-fit xl:w-fit">
        <input
            type="radio"
            name="category"
            id="t"
            checked={category === "t"}
            onChange={() => toggleTableHandler("t")}
            hidden
        />
        <label
            htmlFor="t"
            className={`ml-[2px] font-semibold mt-[2px] mb-[2px] w-[120px] text-[12px] md:text-[15px]
                    tracking-[2px] lg:text-[18px] lg:w-[140px] md:w-[120px] md:mb-[2px] cursor-pointer p-1 py-2 h-fit ${
            category === "t" ? "text-[#A26F35]  border-[#A26F35]" : "text-black"
            } md:text-2xl`}
        >
            Transaction
        </label>

        <input
            type="radio"
            name="category"
            id="l"
            checked={category === "l"}
            onChange={() => toggleTableHandler("l")}
            hidden
        />
        <label
            htmlFor="l"
            className={`ml-[2px] font-semibold mt-[2px] mb-[2px] w-[120px] text-[12px] md:text-[15px]
                    tracking-[2px] lg:text-[18px] lg:w-[140px] md:w-[120px] md:mb-[2px] cursor-pointer p-1 py-2 h-fit ${
            category === "l" ? "text-[#A26F35]  border-[#A26F35] " : "text-black"
            } md:text-2xl`}
        >
            Legal
        </label>
        </div>
    );
    };

    export default CategoryRadioButtons;
