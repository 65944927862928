    import React from 'react';
    import './CardsContainer.css'; // You can create a CSS file for styling
    import ImageCard from "../card/ImageCard";
    import img1 from "../../assets/assets/whatsnew-img/unicorn-card-img.png";
    import img2 from "../../assets/assets/whatsnew-img/series-investors.jpg";
    import img3 from "../../assets/assets/whatsnew-img/money-floods.jpg";
    import img4 from "../../assets/assets/whatsnew-img/conclave.png";


    const CardsContainer = () => {
        const cardData = [
            {
                title: 'Unicorn Startup',
                description: 'No. of Unicorn Startups in India Has Crossed 100 - View the Full List',
                link:"http://vi-gyan.com/",
                linkText:"Learn More →",
                ds_link:"/Indian-union-tracker",
                imageSrc: img1,
            },
            {
                title: 'Press Release',
                description: 'Series A investors setting high bar for quality: Report',
                link:"/Press-Release",
                linkText:"Learn More →",
                ds_link:"https://ventureintelligence.blogspot.com/2023/05/series-investors-setting-high-bar-for.html",
                imageSrc: img2,
            },
            {
                title: 'Media Coverage',
                description: 'Venture Money Floods Into Indian Startups - Wall Street Journal (quoting Venture Intelligence data)',
                link:"/Press-Release#media-coverage",
                ds_link:"https://www.wsj.com/articles/venture-money-floods-into-indian-startups-1427748367",
                linkText:"Learn More →",
                imageSrc:img3,
            },
            {
                title: 'Events',
                description: 'PE-VC Conclave : Delhi-NCR saw the coming together of the Best Minds in the Private Co. Investing Ecosystem',
                link:"/Events",
                ds_link:"https://ventureintelligence.com/conclave23-d.html",
                linkText:"Learn More →",
                imageSrc: img4,
                },
        ];
    return (
        <div className="cards-container mt-8">
            {cardData.map((card, index) => (
                <ImageCard key={index} {...card} />
            ))}
        </div>
    );
    };

    export default CardsContainer;
