import React from "react";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  const navigate = useNavigate();
  
  const navigateToLeague = () => {
    // 👇️ navigate to /leagues
    navigate('/leagues');
  };
  return (
    <button
      className={`px-4 py-2 bg-gray-800 text-white rounded-md ${
        props.className ? props.className : ""
      }`} onClick={props.onClick ? props.onClick : navigateToLeague}
    >
      {props.children}
    </button>
  );
};

export default Button;
