import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Clogo from "../../assets/assets/logo-b.png";
import NavItems from "../utils/NavLinks";

const Navbar = ({ navLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleMobileNav = () => {
    setIsNavOpen((prevState) => !prevState);
    setIsOpen((prevState) => !prevState);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderNavOptions = (options) => {
    if (options) {
      return (
        <div className="mt-[0.5rem] p-2 min-w-[12rem] hidden group-hover:flex flex-col bg-[aliceblue] gap-1 z-30 rounded-md lg:fixed">
          {options.map((option) => (
            <a
              href={option.to}
              key={option.name}
              className="py-2 px-1.5 hover:bg-blue-50 text-black hover:text-[#A07924]"
            >
              {option.name}
            </a>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`py-4 px-3 flex items-center justify-between lg:mx-auto lg:justify-evenly bg-white lg:sticky w-[100%] top-0 z-50 transition-shadow duration-300 ${
        hasScrolled ? "shadow-md" : ""
      }`}
    >
      <div className="flex items-center justify-between gap-56 ">
        <div>
          <a href="/">
            <img
              src={Clogo}
              alt="VI"
              className="mx-2 w-32 sm:w-36 z-30 cursor-pointer"
            />
          </a>
        </div>
        <div
          className={`flex-col text-sm ${
            isNavOpen
              ? "mt-4 p-2 w-[70vw] absolute top-12 right-4 z-30 flex justify-center gap-4 bg-white rounded-lg transition-all duration-300 ease-in-out sm:w-4/12 lg:w-1/4"
              : "hidden"
          } lg:static lg:flex lg:flex-row lg:items-center lg:justify-end lg:bg-transparent lg:gap-6`}
        >
          {navLinks.map((link) => (
            <div
              className="px-2 py-1 lg:py-2 lg:px-4 group rounded-md hover:text-blue-100 lg:hover:text-blue-50"
              key={link.name}
            >
              <NavItems to={link.to} page={link.name} options={link.options} />
              {renderNavOptions(link.options)}
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={toggleMobileNav}
        className="mr-1 p-2 absolute right-5 z-30 rounded-md cursor-pointer lg:hidden bg-white  mt-2"
      >
        <FontAwesomeIcon
          icon={isOpen ? faTimes : faBars}
          size="lg"
          className={`mr-4 w-8 duration-75 cursor-pointer ${
            isOpen ? "text-blue-500" : "text-gray-500"
          }`}
        />
      </div>
    </div>
  );
};

export default Navbar;
