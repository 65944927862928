import React from "react";
import Container from "../layout/Container";
import CardsContainer from "../cardsContainer/CardsContainer";


const Blog = () => {
  return (
    <div id="whats-new" className=" xl:w-full overflow-x-hidden overflow-y-hidden bg-slate-100">
      <Container>
        <div className="flex-col">
          <h1 className="text-4xl font-bold text-center">
            Whats New
          </h1>
          <div id="cards-carousel" className="flex flex-wrap justify-center mx-2 my-2">
            <CardsContainer />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Blog;
