import React from "react";
import Layout from "../../../components/layout";
import CfsHeroSec from "./CfsHeroSec";

import Visualisation from "../../../components/common/Visualisation";
import Features from "../../../components/common/Features";
import Company from "./Company";

import Review from "../../home/Review";
import Integration from "../../../components/common/Integration";
import Modeling from "../../../components/common/Modeling";
import Demo from "../../../components/common/Demo";

const CFS = () => {
  return (
    <Layout>
      <CfsHeroSec />
      <Modeling />
      <Company />
      <Integration />
      <Company />
      <Visualisation />
      <Features />
      <Review />
      <Demo />
    </Layout>
  );
};

export default CFS;
