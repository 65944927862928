import React from "react";

import Container from "../../../components/layout/Container";

import marley from "../../../assets/reviews/marley spoon.png";
import vector from "../../../assets/reviews/Vector.png";
import fok from "../../../assets/reviews/Dina Fok.jpeg";
import Button from "../../../components/utils/Button";

const Company = () => {
  return (
    <div className="bg-blue-50">
      <Container className="flex flex-col-reverse gap-12 lg:p-0 lg:py-20 lg:flex-row lg:justify-center lg:gap-8">
        <div className="h-[800px] sm:max-h-[480px] lg:w-[60%]">
          <div className="p-16 pb-32 relative bg-amber-300 text-white rounded-t-3xl">
            <img src={marley} alt="" className="w-60" />
            <p className="pt-2 text-xs font-semibold">
              750 EMPLOYEES, POST-IPO
            </p>
            <div className="absolute top-36 md:w-10/12 left-0 md:left-12">
              <div className="mx-2 p-8 bg-gray-800 text-white text-lg rounded-2xl">
                “We evaluated a range of financial modelling products, but
                Causal was the only platform that provided the modelling
                flexibility that we needed, without requiring external
                consultants to manage it. Causal has freed up my team’s time to
                focus on value-additive work with confidence in our numbers, and
                the Causal team are a pleasure to work with.”
              </div>
              <img src={vector} alt="" className="ml-10" />
              <div className="mt-4 flex gap-4 items-center">
                <img src={fok} alt="" width={"60px"} className="rounded-full" />
                <div>
                  <p className="mb-2 text-gray-800 text-xs font-semibold">
                    DINA FOK
                  </p>
                  <p className="text-gray-600 text-xs font-bold">
                    BUSINESS ANALYSIS LEAD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start gap-8 lg:w-[40%]">
          <div>
            <h1 className="mb-4 text-gray-800 font-bold text-2xl md:text-3xl">
              Complex revenue modelling made simple
            </h1>
            <p className="text-base text-gray-600">
              Before Causal, Aidan was spending countless hours every month on
              routine finance processes and answering requests from the company
              — all while doing 5 other jobs.
            </p>
            <div className="my-4"></div>
            <p className="text-base text-gray-600">
              With Causal, the Inscribe team can access targets and metrics
              self-serve, and Aidan can focus on helping the business make
              better decisions.
            </p>
          </div>
          <Button>Read Case Study</Button>
        </div>
      </Container>
    </div>
  );
};

export default Company;
