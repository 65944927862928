import React, { useState, useEffect } from "react";
import bg from "../../assets/backgrounds/main_grid.png";
import hero from "../../assets/assets/dashboard.png";
import hero2 from "../../assets/assets/dashboard4.jpg";
import hero3 from "../../assets/assets/dashboard5.jpg"
import Container from "../../components/layout/Container";
import Button from "../utils/Button";
import { Target } from 'lucide-react';

const HeroSec = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide < 2 ? prevSlide + 1 : 0));
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  const slides = [
    // Slide 1
    <div key="slide1" className="flex flex-col justify-center gap-4 md:flex-row lg:h-[25rem] items-center mb-[70px] ">
      <div className="leading-5 lg:max-w-lg">
        <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
          YOUR GATEWAY TO PRIVATE COMPANY INSIGHTS
        </p>
        <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
          <div className="font-medium headings leading-tight">
            Venture Intelligence,
          </div>
          <div className="font-[initial] font-bold">
            Your Premier Source for Private Company Data in India
          </div>
        </h1>
        <h2 className="text-gray-900 font-semibold text-[1.10rem] tracking-tight leading-7">
          Unlock opportunities, make informed decisions, and stay ahead in
          the dynamic world of private company investments, transactions,
          financials, and valuations.
        </h2>
        <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
          <Button className="my-4 text-sm" onClick={() => setCurrentSlide(1)}>More About Us</Button>
        </div>
      </div>
      <div className="hidden lg:block lg:max-w-sm xl:max-w-sm ml-8">
        <img src={hero} alt="Dashboard" className="" />
      </div>
    </div>,
    // Slide 2
    <div key="slide2" className="flex flex-col justify-center gap-4 md:flex-row lg:h-[25rem] items-center">
      <div className="leading-5 lg:max-w-lg">
        <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
          ABOUT VENTURE INTELLIGENCE
        </p>
        <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
          <div className="font-medium headings leading-tight">
            Your Trusted Partner
          </div>
          <div className="font-[initial] font-bold">
            In Private Company Intelligence
          </div>
        </h1>
        <ul className="space-y-4 text-gray-900 font-semibold text-[1.10rem] tracking-tight leading-7">
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Launched in 2002, the VI service is India's leading source for private company financials, transactions (PE, VC, M&A), and valuations</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>We track, analyze, and summarize private company financials, transactions, and valuation trends, delivering them in efficient formats to help subscribers spot the right business opportunities</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Our research is widely used by industry practitioners, entrepreneurs, educational institutions, and the media</p>
          </li>
        </ul>
        {/* <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
          <Button className="my-4 text-sm">Learn More</Button>
        </div> */}
      </div>
      <div className="hidden lg:block lg:max-w-sm xl:max-w-sm ml-8">
        <img src={hero2} alt="Dashboard" className="" />
      </div>
    </div>,
    // Slide 3
    <div key="slide3" className="flex flex-col justify-center gap-4 md:flex-row lg:h-[25rem] items-center">
      <div className="leading-5 lg:max-w-lg">
        <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
          OUR DIFFERENTIATORS
        </p>
        <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
          <div className="font-medium headings leading-tight">
          </div>
          <div className="font-[initial] font-bold">
            {/* Venture Intelligence */}
            Why Choose Us?
          </div>
        </h1>
        <ul className="space-y-4 text-gray-900 font-semibold text-[1.10rem] tracking-tight leading-7">
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>On ground presence since 2002</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Transaction Valuations Multiples</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Return Multiples of PE/VC Deals</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Deals unavailable in public domain</p>
          </li>
          <li className="flex items-start">
            <Target className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" />
            <p>Independent. Accurate. Reliable.</p>
          </li>
        </ul>
        {/* <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
          <Button className="my-4 text-sm">Contact Us</Button>
        </div> */}
      </div>
      <div className="hidden lg:block lg:max-w-sm xl:max-w-sm ml-[5rem]">
        <img src={hero3} alt="Dashboard" className="" />
      </div>
    </div>
  ];

  return (
    <div 
      style={{ backgroundImage: `url(${bg})` }} 
      className="opacity-100 relative overflow-hidden h-[82vh] flex items-center"
    >
      <Container className="flex flex-col justify-center items-center w-full">
        <div 
          className="flex transition-transform duration-500 ease-in-out w-full"
          style={{ 
            transform: `translateX(-${currentSlide * 100}%)`,
            width: `${slides.length * 100}%`
          }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="w-full flex-shrink-0">
              {slide}
            </div>
          ))}
        </div>
      </Container>
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${
              currentSlide === index ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSec;