import React from "react";
import Container from "../layout/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/assets/logo-b.png";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const ContactUs = ({ navLinks }) => {
  return (
    <div className="w-100%  bg-slate-300">
      <Container className="m-0">
        <div className="">
          <div className="mt-8 mr-10 md:mr-12  flex justify-center">
            <img src={logo} alt="foooter_logo" />
          </div>
          <div className="p-4  flex flex-wrap md:flex-row ">
            {navLinks?.map((link) => (
              <div key={link.name} className="p-4 flex flex-col gap-4">
                <h2 className="text-sm text-indigo-950 font-semibold">
                  {link.name}
                </h2>
                <div className="w-48 flex flex-col gap-6 text-gray-600 text-sm">
                  {link?.options?.map((option) => (
                    <a key={option.name} href={option.to}>
                      {option.name}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className=" mb-8 p-8 flex justify-evenly flex-col md:flex-row lg:flex-row text-sm">
              <div>
                <p className="my-6 font-semibold">
                  Registered Office & Mail Address
                </p>
                <span className="my-2 flex gap-2 ml-2">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    style={{ color: "#000000" }}
                  />
                  1, Maharani Chinnamba Road, Alwarpet,
                </span>
                <p className="mx-6 my-2">Chennai-600 018, INDIA</p>
                <span className="flex gap-2 ml-2">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ color: "#000000" }}
                  />
                  +91-44-42185180
                </span>
              </div>
              <div>
                <p className="my-6 font-semibold">Chennai OMR Office</p>
                <span className="my-2 flex gap-2 ml-2">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    style={{ color: "#000000" }}
                  />
                  30, 1st Street, Srinivasa Nagar, Kottivakkam,
                </span>
                <p className="my-2 mx-6">Chennai-600 096, INDIA</p>
                <span className="flex gap-2   ml-2">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ color: "#000000" }}
                  />
                  +91-44-42185182
                </span>
              </div>
            </div>
          </div>
          <p className="flex gap-2 justify-center items-center text-sm">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              style={{ color: "#000000" }}
            />
            <a href="mailto:bizdev@ventureintelligence.com">
              bizdev@ventureintelligence.com
            </a>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
