import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Maximize2, X } from 'lucide-react';

import leaguetableImage from '../../assets/assets/league2.jpg';
import ancillaryProducts from '../../assets/assets/products/ancillaryproducts2.png';
import financials from '../../assets/assets/products/cfs2.png';
import newsLetter from '../../assets/assets/newsletter2.jpg';
import PeVcDirectory from '../../assets/assets/products/directoryPevcInvestors2.png';
import mergerAndAcquisition from '../../assets/assets/products/ma2.png';
import pere from '../../assets/assets/products/pere2.png';
import privateEquity from '../../assets/assets/products/privateequity3.png';

const FullScreenImageViewer = ({ image, onClose, product }) => {
  return (
    <motion.div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 backdrop-blur-sm"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="relative w-[90%] max-w-4xl max-h-[90vh] bg-white/30 backdrop-blur-lg rounded-2xl overflow-hidden shadow-2xl"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        <div className="absolute top-4 right-4 z-10 flex space-x-2">
          <button 
            onClick={onClose}
            className="bg-white/50 hover:bg-white/70 backdrop-blur-sm p-2 rounded-full transition-all"
          >
            <X className="h-6 w-6 text-gray-800" />
          </button>
        </div>
        <img 
          src={image} 
          alt={`${product} full view`} 
          className="w-full h-full object-contain max-h-[80vh]"
        />
      </motion.div>
    </motion.div>
  );
};

const ProductCard = ({ type, product, summary, image }) => {
  const [isFullScreenImage, setIsFullScreenImage] = useState(false);

  return (
    <>
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-xl shadow-lg overflow-hidden w-full max-h-[500px]"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {/* Image Section */}
        <div className="bg-white flex items-center justify-center w-full h-[300px] md:h-full relative">
          <img 
            src={image} 
            alt={`${product} screenshot`} 
            className={`w-[90%] h-[90%] object-contain ${product === 'Deal Digest Newsletter' ? 'max-h-[320px]' : ''}`}
          />
          <button 
            onClick={() => setIsFullScreenImage(true)}
            className="absolute bottom-2 left-4 bg-white/50 flex flex-row gap-2 hover:bg-white/70 backdrop-blur-sm p-2 rounded-full transition-all"
          >
            View Image<Maximize2 className="h-5 w-5 text-gray-800 mt-[2px]" />
          </button>
        </div>

        {/* Content Section */}
        <div className="p-6 md:p-8 flex flex-col justify-between overflow-y-auto">
          <div>
            <span className="text-xs font-semibold text-[#bc8b20] uppercase tracking-wider">
              {type}
            </span>
            <h3 className="text-xl md:text-2xl font-bold mt-2 text-gray-800">
              {product}
            </h3>
            
            <ul className="space-y-3 mt-4 md:mt-6 text-gray-600">
              {summary.map((point, index) => (
                <li 
                  key={index} 
                  className="flex items-start space-x-3 group"
                >
                  <span className="h-2 w-2 bg-[#bc8b20] rounded-full mt-2 group-hover:bg-[#d6b15f] transition-all"></span>
                  <p className="text-sm md:text-base">{point}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isFullScreenImage && (
          <FullScreenImageViewer 
            image={image} 
            product={product}
            onClose={() => setIsFullScreenImage(false)} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

const Products = () => {
  const products = [
    {
      type: "PRIVATE EQUITY",
      product: "Private Equity - Venture Capital Database",
      summary: [
        "Investments since 1998 and exits since 2004 in India-based companies by private equity and venture capital firms.",
        "Includes sub-databases tracking Angel Investing and Incubation/Acceleration activity.",
        "Profiles of PE & VC firms; PE/VC-backed companies and Companies incubated at various Incubators/Accelerators across India.",
        "Shareholding details - Comprehensive & Post Transaction Changes."
      ],
      image: privateEquity
    },
    {
      type: "FINANCIALS",
      product: "Company Financials Search",
      summary: [
        "Private Company Financials data covering 50k+ companies",
        "Includes Profit & Loss, Balance Sheet, Cash Flow statements and Ratios",
        "Company filings (as reported to MCA)",
        "Index of Charges Data",
        "Various Powerful filters to extract the data"
      ],
      image: financials
    },
    {
      type: "MERGER & ACQUISITIONS",
      product: "Merger and Acquisitions Database",
      summary: [
        "Inbound, outbound and domestic deals involving India-based companies.",
        "Enterprise Valuation and multiples",
        "Includes minority stake acquisitions"
      ],
      image: mergerAndAcquisition
    },
    {
      type: "EQUITY",
      product: "Private Equity-Real Estate Database",
      summary: [
        "Investments, Exits and M&A in India-based Real Estate companies and projects.",
        "Comprehensive project details",
        "Trends and analytics in PE-RE sector"
      ],
      image: pere
    },
    {
      type: "NEWSLETTER",
      product: "Deal Digest Newsletter",
      summary: [
        "India's First & Most Exhaustive Transactions Newsletter",
        "Premium Daily Edition",
        "Free Weekly Edition"
      ],
      image: newsLetter
    },
    {
      type: "DIRECTORY",
      product: "Directory of PE-VC Investors",
      summary: [
        "The India Private Equity / Venture Capital Directory",
        "The India Private Equity-Real Estate Directory",
        "Directory of Investors from China, Japan, S.E. Asia Active in India",
        "Directory of Early Stage Investors in India",
        "Limited Partners Directory",
        "Family Office Directory"
      ],
      image: PeVcDirectory
    },
    {
      type: "Others",
      product: "Other Products & Services",
      summary: [
        "Series A Landscape Report",
        "Private Equity Impact Report",
        "Customized research and analytics"
      ],
      image: ancillaryProducts
    },
    {
      type: "LEAGUE TABLES",
      product: "League Tables",
      summary: [
        "The Venture Intelligence League Tables, the first such initiative exclusively tracking transactions involving India-based companies.",
        "Based on value of PE and M&A transactions",
        "Compiled based on transactions submitted by advisory firms"
      ],
      image: leaguetableImage
    }
  ];

  const [activeProduct, setActiveProduct] = useState(0);

  const handlePrevious = () => {
    setActiveProduct((prev) => (prev === 0 ? products.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setActiveProduct((prev) => (prev === products.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="py-12 bg-gradient-to-b from-gray-50 to-white">
      <div className="">
        <h2 className="text-4xl container mx-auto px-4 max-w-5xl font-extrabold text-center mb-12 text-gray-800">
          Our Products
        </h2>
        
        <div className="relative w-[86vw] mx-auto px-4">
          <AnimatePresence mode="wait">
            <motion.div 
              key={activeProduct}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
              className="flex justify-center"
            >
              <ProductCard {...products[activeProduct]} />
            </motion.div>
          </AnimatePresence>

          {/* Navigation Buttons */}
          <motion.button
            className="absolute top-1/2 -left-16 transform -translate-y-1/2 
                       bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 
                       focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={handlePrevious}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ChevronLeft className="h-6 w-6 text-gray-600" />
          </motion.button>

          <motion.button
            className="absolute top-1/2 -right-16 transform -translate-y-1/2 
                       bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 
                       focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={handleNext}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ChevronRight className="h-6 w-6 text-gray-600" />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default Products;