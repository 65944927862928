import React from "react";

import circle from "../../../assets/backgrounds/circle.png";
import Container from "../../../components/layout/Container";

const CfsHeroSec = () => {
  return (
    <div
      style={{ backgroundImage: `url(${circle})` }}
      className="bg-center bg-no-repeat"
    >
      <Container className="py-16 flex justify-center">
        <div className="flex flex-col justify-center items-center gap-2 leading-5 lg:max-w lg:max-w-xl lg:h-[60vh]">
          <p className="my-2 text-blue-500 text-xs font-semibold">
            CAUSAL FOR FINANCE
          </p>
          <h1 className="my-4 text-3xl leading-[1.12em] font-bold tracking-tighter md:text-[50px]">
            Drive your business with Revenue-Led Planning
          </h1>
          <h2 className="text-gray-900 font-semibold text-lg">
            Model effortlessly, forecast collaboratively, story   tell effectively
          </h2>

          <button className="my-4 p-2 bg-blue-500 text-white text-sm rounded-md">
            Book a Demo Today
          </button>
        </div>
      </Container>
    </div>
  );
};

export default CfsHeroSec;
