import React from 'react';

const ImageCard = ({ imageSrc, title,ds_link , description , link , linkText  }) => {
    return (
        <div className="card lg:w-[248px] xl:w-[ 248px] md:w-[ 228px] w-[238px]">
            <div className="card__img" style={{ backgroundImage: `url(${imageSrc})` }}></div>
                <a href="/" className="card_link">
                    <div className="card__img--hover" style={{ backgroundImage: `url(${imageSrc})` }}></div>
                </a>
            <div className="card__info">
                    <span className="card__category">{title}</span>
                    <a href={ds_link}>
                        <h3 className="card__title font-medium">{description}</h3>
                    </a>
                    <span className="card__by"><a href={link} target="_blank" rel='noreferrer'  className="card__author" title="author">{linkText}</a></span>
            </div>
        </div>
        
    );
};

export default ImageCard;
