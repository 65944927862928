import React from 'react';
import './styles.css'

const CardsContainer = () => {
    return (
        <div className='theme-container'>
            <div className="row1-container ">

                <div className="box box-down cyan">
                    <h2 className='mb-3'>Jun-22</h2>
                    <p className='mb-3'>
                        Seed & Series A investments providing strong support to Indian
                        Startup ecosystem: Report
                    </p>
                    <a
                        href="https://ventureintelligence.blogspot.com/2022/06/seed-series-investments-providing.html"
                        target="_blank"
                        rel="noreferrer"
                        class="inline-flex items-center text-blue-600 hover:underline hover:cursor-pointer"
                    >
                        Learn More
                    </a>
                    <img
                        src="https://assets.codepen.io/2301174/icon-supervisor.svg"
                        alt=""
                    />
                </div>

                <div className="box red">
                    <h2 className='mb-3'>Jan-22</h2>
                    <p className='mb-3'>PE-VC Investments in 2021 hit all time high of $63 Billion</p>
                    <a
                        href="https://ventureintelligence.blogspot.com/2022/01/pe-vc-investments-in-2021-reach-all.html"
                        target="_blank"
                        rel="noreferrer"
                        class="inline-flex items-center text-blue-600 hover:underline hover:cursor-pointer"
                    >
                        Learn More
                    </a>
                    <img
                        src="https://assets.codepen.io/2301174/icon-team-builder.svg"
                        alt=""
                    />
                </div>

                <div className="box box-down blue">
                    <h2 className='mb-3' >Feb-23</h2>
                    <p className='mb-3'>
                        Avendus tops League Table for Transaction Advisors to Private Equity
                        deals in 2022
                    </p>
                    <a
                        href="https://ventureintelligence.blogspot.com/2023/02/avendus-tops-league-table-for.html"
                        target="_blank"
                        rel="noreferrer"
                        class="inline-flex items-center text-blue-600 hover:underline hover:cursor-pointer"
                    >
                        Learn More
                    </a>
                    <img
                        src="https://assets.codepen.io/2301174/icon-calculator.svg"
                        alt=""
                    />
                </div>

                <div className="box orange">
                    <h2 className='mb-3'>Mar-17</h2>
                    <p className='mb-3'>Private Equity-backed cos. outperform their peers: Study</p>
                    <a
                        href="https://ventureintelligence.blogspot.com/2017/03/private-equity-backed-cos-outperform.html"
                        target="_blank"
                        rel="noreferrer"
                        class="inline-flex items-center text-blue-600 hover:underline hover:cursor-pointer"
                    >
                        Learn More
                    </a>
                    <img src="https://assets.codepen.io/2301174/icon-karma.svg" alt="" />
                </div>

            </div>

            <div className="row2-container">

                

            </div>
        </div>
    )
}

export default CardsContainer
