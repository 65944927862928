import React from "react";
import Container from "../layout/Container";

import half_circle from "../../assets/backgrounds/half_circle.png";

const Demo = () => {
  return (
    <div
      style={{ backgroundImage: `url(${half_circle})` }}
      className="bg-gray-800 bg-center bg-no-repeat"
    >
      <Container className="py-16 flex justify-center">
        <div className="flex flex-col justify-center items-center gap-2 text-center leading-5 lg:max-w lg:max-w-xl h-[50vh]">
          <h1 className="my-4 text-3xl text-white leading-[1.12em] font-bold tracking-tighter md:text-[32px]">
            Turn your Finance team into a true business partner.
          </h1>
          <h2 className="text-gray-400 font-semibolds text-lg">
            We've helped hundreds of companies streamline their FP&A processes.
            We'd love to do the same for you.
          </h2>

          <button className="my-4 px-12 py-3 bg-blue-500 text-white font-semibold text-sm rounded-md">
            Book a Demo
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Demo;
