import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

const NavItems = ({ to, page, options }) => {
  // const showOptions = () => {};
  return (
    <NavLink
      to={to}
      className={`text-black text-sm font-semibold flex items-center justify-between lg:gap-1 hover:text-[#A07924]`}
    >
      {page}

      {options ? (
        <FontAwesomeIcon
          icon={faAngleDown}
          size="sm"
          style={{ color: "#4f5c6a" }}
        />
      ) : (
        ""
      )}
    </NavLink>
  );
};

export default NavItems;
