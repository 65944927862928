import React from "react";
import Button from "../utils/Button";

import Container from "../layout/Container";
import dataIntegration from "../../assets/data_integration.png";

const Integration = () => {
  return (
    <div className="bg-white">
      <Container className="my-8 py-20 flex flex-col gap-8 lg:px-0 lg:flex-row">
        <div className="mx-auto flex flex-col gap-4 items-start">
          <h3 className="text-sm text-blue-500  font-semibold">
            DATA INTEGRATIONS
          </h3>
          <div>
            <h1 className="my-4 text-gray-800 font-bold text-4xl">
              Automate and unite data in seconds
            </h1>
            <p className="text-base text-gray-600">
              Pull financial data directly from your{" "}
              <strong>accounting system</strong> and combine it with operational
              data from your <strong>CRM </strong> and data warehouse to build
              fully integrated models.
            </p>
            <br />
            <p className="text-base text-gray-600">
              <strong>Consolidate data</strong>
              from multiple sources and business entities into a single view,
              with the ability to{" "}
              <strong>drill down into granular detail</strong>when you need.
            </p>
            <br />
            <p className="text-base text-gray-600">
              Automatically track the performance of your model against actual
              data, and <strong>compare model versions</strong>
              (e.g. Budget) against actuals and re-forecasts.
            </p>
          </div>
          <Button className="my-2">See all integrations</Button>
        </div>
        <div className="mx-auto h-[35rem]">
          <img
            src={dataIntegration}
            alt=""
            className="shadow-2xl rounded-xl w-[100rem]"
          />
        </div>
      </Container>
    </div>
  );
};

export default Integration;
