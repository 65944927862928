        import React from "react";
        import { useState , useEffect } from "react";
        import CategoryRadioButtons from "../../components/league components/CategoryRadioBtn";
        import SubcategorySelector from "../../components/league components/SubCategorySelector";
        import { motion, AnimatePresence } from "framer-motion";
        import SearchContent from "../../components/league components/SearchContent";
        import ExportBtn from "../../components/league components/ExportBtn";

        const CategorySelector = ({
        category,
        toggleSubTableHandler,
        toggleMainTableHandler,
        subCategory,
        setSubCategory,
        searchHandler,
        isChecked,
        setIsChecked,
        setTableData,
        setLoading,
        showSearchForm,
        setShowSearchForm,
        sortedData
        }) => {
        // State to track whether the search form is visible
        const [buttonState, setButtonState] = useState("filter");

        const [hideAndSeek,setHideAndSeek] = useState(true);
        const[fetchData , setFetchData] = useState(false);
        const[resetDropdowns,SetResetDropdowns] = useState(false);
        const [isMobile, setIsMobile] = useState(window.innerWidth < 740);

        // values 
        const [dropdownYear , SetDropdownYear] = useState(null);
        const [dropdownIndustryType , SetDropdownIndustryType] = useState(null);
        const [dropdownInclude , SetDropdownInclude] = useState(null);

        const [includeOtherAdvisory, setIncludeOtherAdvisory] = useState(false);
        const [searching , SetSearching ] = useState(false);    

        const handleSearchIconClick = () => {
            if (fetchData) {
                dataFetching();
            }
            SetSearching((searching) => !searching);
        };

        const handleIncludeOtherAdvisoryChange = () => {
            setIncludeOtherAdvisory(!includeOtherAdvisory);
        };
//intimating 
        const handleIncludeExcludeChange = (option) => {
            // Perform actions based on the include/exclude option
            if(option){
                // console.log("Include/Exclude option changed:", option);
                setHideAndSeek(false)
                setFetchData(true)
                SetDropdownInclude(option)
                // console.log(option)
            }
        };

        const handleSearchByYear = (year) => {
            // Perform actions based on the include/exclude option
            if(year){
                // console.log("Include/Exclude option changed:", option);
                setHideAndSeek(false)
                setFetchData(true)
                SetDropdownYear(year);
            }
        };

        const handleSearchByIndustry = (selectedIndustry) => {
            // Perform actions based on the selected industry
            if (selectedIndustry) {
                setHideAndSeek(false);
                setFetchData(true);
                SetDropdownIndustryType(selectedIndustry);
            }
        };

        const initialApi = "https://api-hub.ventureintelligence.com/public/league-table/main/"

        const [apiUrl, setApiUrl] = useState(
            initialApi
        );

        useEffect(() => {
                const buildApiUrl = () => {
                // Build the complete URL based on state variables
                const categoryValue = `${category === "l" ? "legal" : "transaction"}`;
                const queryParams = new URLSearchParams({
                    deal_type: subCategory ? subCategory : "PE",
                    date__year: dropdownYear ? dropdownYear : 2023,
                });
            
                if (dropdownIndustryType) {
                    queryParams.append("industry", dropdownIndustryType);
                }
            
                if (!includeOtherAdvisory) {
                    queryParams.set(
                    "advisor_type",
                    categoryValue ? categoryValue : "transaction"
                    );
                } else {
                    queryParams.set("advisor_type__icontains", categoryValue);
                }
            
                const queryParamsString = queryParams.toString();
                const newApiUrl = `https://api-hub.ventureintelligence.com/public/league-table/main/?${queryParamsString}`;
                setApiUrl(newApiUrl);
                };
            
                // Call the buildApiUrl function whenever relevant state variables change
                buildApiUrl();
            }, [category, subCategory, includeOtherAdvisory , searching]);
        

            useEffect(()=>{
                setApiUrl(initialApi);
            },[])

        const dataFetching = async () => {
            try {
                setLoading(true);

                
                // console.log(dropdownInclude === "include" ? dropdownIndustryType : `-${dropdownIndustryType}`)
                console.log(apiUrl);
                // console.log(queryParams);
                // console.log(dropdownInclude)
            
                // Make the GET request using fetch
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                });
            
                // Check if the request was successful (status code 2xx)
                if (response.ok) {
                    // console.log(`Selected category: ${category === "l" ? "Legal" : "Transaction"}`);

                    const result = await response.json();
                    const tableData = result.data;
                    setTableData(tableData);
                } else {
                    // Handle the error (non-2xx status code)
                    console.error('API error:', response.statusText);
                }
            
                // After completing data fetching, reset the state
                setTimeout(() => {
                    SetResetDropdowns(false);
                    setHideAndSeek(true);
                    setLoading(false);
                }, 700);
                } catch (error) {
                // Handle other errors
                console.error('Error during data fetching:', error.message);
                }
            };

            useEffect(() => {
                // Perform initial data fetching when component mounts
                dataFetching();
            }, [apiUrl]);

            useEffect(() => {
                const handleResize = () => {
                setIsMobile(window.innerWidth < 770);
                };
            
                window.addEventListener("resize", handleResize);

                return () => {
                    window.removeEventListener("resize", handleResize);
                };
                }, []);


            useEffect(() => {
                // Perform initial data fetching when component mounts
                dataFetching();
            }, [category, subCategory]);



        return (
            <div className="text-left ml-6 flex-col mt-8">

                <div className="mb-4">
                    <h1 className=" md:text-2xl text-left md:mx-8 md:text-center">
                        Top {category==="t" ? "Transactions" : "Legal"} Advisors - {subCategory === "ma" ? "M&A" : subCategory.toUpperCase()}  ({dropdownYear ? dropdownYear : 2013})  {dropdownIndustryType ? `in ${dropdownIndustryType}`  : ""}
                    </h1>
                </div>

                <div className="flex flex-col md:flex-row md:justify-between">
                    {/* First component (CategoryRadioButtons) on the first row */}
                    <CategoryRadioButtons
                        category={category}
                        toggleTableHandler={toggleMainTableHandler}
                    />

                    {/* Second and third components on the second row */}
                    <div className="flex justify-between">
                        <SubcategorySelector
                        subCategory={subCategory}
                        category={category}
                        toggleTableHandler={toggleSubTableHandler}
                        setSubCategory={setSubCategory}
                        />

                        <ExportBtn
                        showSearchForm={showSearchForm}
                        subCategory={subCategory}
                        sortedData={sortedData}
                        setShowSearchForm={setShowSearchForm}
                        category={category}
                        dropdownYear={dropdownYear}
                        dropdownIndustryType={dropdownIndustryType}
                        />
                    </div>
                </div>


                <div className="mr-4 ">
                
                    
                    <div className="flex  justify-end h-[74px] ">
                        <AnimatePresence > 
                            {showSearchForm && (
                            <motion.div className=""
                                key="search-form"
                                initial={{ opacity: 0, x: 50 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 50 }}
                                transition={{ duration: 0.5 }}
                            >   
                            
                                <SearchContent 
                                    searchHandler={searchHandler}
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                    category={category}
                                    onIncludeExcludeChange={handleIncludeExcludeChange}
                                    onYearChange={handleSearchByYear}
                                    onIndustryChange={handleSearchByIndustry}
                                    resetDropdowns={resetDropdowns}
                                    SetResetDropdowns={SetResetDropdowns}
                                    handleSearchIconClick={handleSearchIconClick}
                                    isMobile={isMobile}
                                    SetSearching={SetSearching}
                                />
                                
                                <div className="flex gap-3 ml-6 text-xs lg:text-lg md:text-lg xl:text-lg 2xl:text-lg">
                                    <input
                                    type="checkbox"
                                    className=""
                                    checked={includeOtherAdvisory}
                                    onChange={handleIncludeOtherAdvisoryChange}
                                    />
                                    Include Due Diligence, Tax, Other Advisory Services
                                </div>

                            </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                </div>
                
            </div>
        );
        };

        export default CategorySelector;
