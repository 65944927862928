import React from "react";
import Button from "../utils/Button";
import Container from "../layout/Container";

import growth from "../../assets/growth rate.png";

const Modeling = () => {
  return (
    <div className="px-4 bg-white">
      <Container className="my-8 py-20 flex flex-col gap-8 lg:px-0 lg:flex-row">
        <div className="mx-auto flex flex-col gap-4 items-start">
          <h3 className="text-sm text-blue-500  font-semibold">
            FINANCIAL MODELING
          </h3>
          <div>
            <h1 className="my-4 text-gray-800 font-bold text-4xl">
              Dynamic modelling to unlock deeper planning and insights
            </h1>
            <p className="text-base text-gray-600">
              Build and manage <strong>integrated models</strong> that drive
              accurate revenue (cohorts, multi-dimensional) and expense (OPEX,
              labor) projections in a unified way to help you make better
              business decisions in record time.
            </p>
            <br />
            <p className="text-base text-gray-600">
              Set up unlimited <strong>driver-based scenarios,</strong>
              managed automatically by Causal. For uncertain drivers, use
              <strong>ranges</strong> ("5% to 10%") and see the full range of
              possible
            </p>
          </div>
          <Button className="my-2">Try Now</Button>
        </div>
        <div className="mx-auto pt-8 bg-gray-50">
          <img
            src={growth}
            alt=""
            className="shadow-2xl rounded-xl md:max-w-3xl"
          />
        </div>
      </Container>
    </div>
  );
};

export default Modeling;
