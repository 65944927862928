import React from "react";

import forbes from "../../assets/assets/customers/forbes.png";
import scmp from "../../assets/assets/customers/scmp.png";
import quartz from "../../assets/assets/customers/Quartz_logo.png";
import techCrunch from "../../assets/assets/customers/tech-crunch.png";
import ET from "../../assets/assets/customers/economics-times.png";
import mint from "../../assets/assets/customers/Mint_(newspaper)_logo.png";
import theFinancialExpress from "../../assets/assets/customers/TheFinancialExpress.png";
import businessLine from "../../assets/assets/customers/BusinessLine.png";
import moneycontrol from "../../assets/assets/customers/moneycontrol.png";
import CNBC from "../../assets/assets/customers/CNBC_logo.png";
import nikkei from "../../assets/assets/customers/Nikkei-Asia.jpeg";
import yourStory from "../../assets/assets/customers/yourstory_new.png";
import Container from "../layout/Container";
import "./media.css";
import Button from "../utils/Button";

const Media = () => {
  const media = [
    {
      name: "Forbes",
      logo: forbes,
    },
    {
      name: "South China Morning Post",
      logo: scmp,
    },
    {
      name: "Quartz",
      logo: quartz,
    },
    {
      name: "Tech Crunch",
      logo: techCrunch,
    },
    {
      name: "Economics Times",
      logo: ET,
    },
    {
      name: "Mint",
      logo: mint,
    },
    {
      name: "The Financial Express",
      logo: theFinancialExpress,
    },
    {
      name: "Business Line",
      logo: businessLine,
    },
    {
      name: "Moneycontrol",
      logo: moneycontrol,
    },
    {
      name: "CNBC",
      logo: CNBC,
    },
    {
      name: "Nikkei Asia",
      logo: nikkei,
    },
    {
      name: "Your Story",
      logo: yourStory,
    },
  ];

  const animationStyle = {
    animationName: "down_to_up",
    animationDuration: "5s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDelay: "1s",
  };

  const animationStyle_2 = {
    animationName: "up_to_down",
    animationDuration: "5s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDelay: "1s",
  };

  return (
    <div className=" xl:w-full overflow-x-hidden">
      <Container className="">
        <div className="flex flex-col-reverse justify-center gap-2 lg:flex-row lg:h-[25rem] items-center">
          <div className="columns">
            <div className="column column-reverse" style={animationStyle_2}>
              <img src={CNBC} alt={media.name} className="w-28" />
              <img src={yourStory} alt={media.name} className="w-28" />
              <img src={businessLine} alt={media.name} className="w-28" />
              <img src={scmp} alt={media.name} className="w-28" />
            </div>
            <div className="column" key={media.name} style={animationStyle}>
              <img src={forbes} alt={forbes} className="w-28" />
              <img src={mint} alt={media.name} className="w-28" />
              <img
                src={theFinancialExpress}
                alt={media.name}
                className="w-48"
              />
              <img src={nikkei} alt={media.name} className="w-24" />
            </div>
            <div
              className="column column-reverse"
              key={media.name}
              style={animationStyle_2}
            >
              <img src={techCrunch} alt={media.name} className="w-28" />
              <img src={ET} alt={media.name} className="w-24" />
              <img src={quartz} alt={media.name} className="w-28" />
              <img src={moneycontrol} alt={media.name} className="w-28" />
            </div>
          </div>
          <div className="leading-5 lg:max-w-xl xl:max-w-lg">
            <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
              TRUSTED BY MEDIA
            </p>
            <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
              <div className="italic font-medium headings leading-tight">
                Explore Our Impact,
              </div>
              <div className=" font-[inital] font-bold">
                Recognized by Top-Tier Media
              </div>
            </h1>
            <h2 className="text-gray-900 font-semibolds text-[1.10rem] tracking-tight leading-7">
              Our research, reports, and analysts are frequently cited by
              top-tier media organizations, solidifying our reputation as a
              trusted industry source.
            </h2>
            <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
              <Button className="my-4 text-sm">Learn More</Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Media;
