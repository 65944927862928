import React from "react";
import Layout from "../../../components/layout";
import FundingHeroSec from "./FundingHeroSec";

import marley from "../../../assets/reviews/marley spoon.png";
import vector from "../../../assets/reviews/Vector.png";
import fok from "../../../assets/reviews/Dina Fok.jpeg";
import michael from "../../../assets/reviews/michael.png";
import dojo from "../../../assets/customers/dojo-logo.png";
import boxGrid from "../../../assets/backgrounds/box_grid.png";

import Container from "../../../components/layout/Container";
import Explore from "./Explore";

const Funding = () => {
  return (
    <Layout>
      <FundingHeroSec />
      <Explore />
      <Container>
        <h2 className="m-4 text-xs text-blue-500 text-center font-semibold">
          TEAMS
        </h2>
        <h1 className="m-4 text-gray-800 font-bold text-2xl md:text-3xl">
          Join the hundreds of teams who drive their business with Revenue-Led
          Planning
        </h1>
        <div className="flex flex-col lg:flex-row lg:gap-4">
          <div className="h-[800px] sm:max-h-[680px] lg:flex-row lg:w-3/6">
            <div className="p-16 pb-32 relative bg-amber-300 text-white rounded-t-3xl">
              <img src={marley} alt="" className="w-60" />
              <p className="pt-4 text-xs font-semibold text-gray-600">
                750 EMPLOYEES, POST-IPO
              </p>
              <div className="absolute top-36 md:w-10/12 left-0 md:left-12">
                <div className="mx-2 p-8 bg-gray-800 text-white text-lg rounded-2xl">
                  “We evaluated a range of financial modelling products, but
                  Causal was the only platform that provided the modelling
                  flexibility that we needed, without requiring external
                  consultants to manage it. Causal has freed up my team’s time
                  to focus on value-additive work with confidence in our
                  numbers, and the Causal team are a pleasure to work with.”
                </div>
                <img src={vector} alt="" className="ml-10" />
                <div className="mt-4 flex gap-4 items-center">
                  <img
                    src={fok}
                    alt=""
                    width={"60px"}
                    className="rounded-full"
                  />
                  <div>
                    <p className="mb-2 text-gray-800 text-xs font-semibold">
                      DINA FOK
                    </p>
                    <p className="text-gray-600 text-xs font-bold">
                      BUSINESS ANALYSIS LEAD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[800px] sm:max-h-[480px] lg:flex-row lg:w-3/6">
            <div className="p-16 pb-32 relative bg-lime-200 text-white rounded-t-3xl">
              <img src={dojo} alt="" className="w-32" />
              <p className="pt-4 text-xs font-semibold text-gray-600">
                $193M RAISED
              </p>
              <div className="absolute top-36 md:w-10/12 left-0 md:left-12">
                <div className="mx-2 p-8 bg-gray-800 text-white text-lg rounded-2xl">
                  “We evaluated a range of financial modelling products, but
                  Causal was the only platform that provided the modelling
                  flexibility that we needed, without requiring external
                  consultants to manage it. Causal has freed up my team’s time
                  to focus on value-additive work with confidence in our
                  numbers, and the Causal team are a pleasure to work with.”
                </div>
                <img src={vector} alt="" className="ml-10" />
                <div className="mt-4 flex gap-4 items-center">
                  <img
                    src={michael}
                    alt=""
                    width={"60px"}
                    className="rounded-full"
                  />
                  <div>
                    <p className="mb-2 text-gray-800 text-xs font-semibold">
                      MICHAEL OVERELL
                    </p>
                    <p className="text-gray-600 text-xs font-bold">
                      HEAD OF REVENUE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div
        style={{ backgroundImage: `url(${boxGrid})` }}
        className="bg-gray-800"
      >
        <Container>
          <div className="py-12 text-center">
            <h1 className="my-4 text-2xl text-white leading-[1.12em] font-bold tracking-tighter md:text-[32px]">
              Take Finance to New Heights
            </h1>
            <button className="my-4 p-2 bg-blue-500 text-white text-sm rounded-md">
              Book a Demo Today
            </button>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Funding;
