import { Navigate, Route, Routes } from "react-router";
import Home from "./pages/home/index";
import CFS from "./pages/products/cfs/index";
import Funding from "./pages/products/funding/index";
import MA from "./pages/products/m&a/index";
import Leagues from "./pages/league/index";
import UnicornTracker from "./pages/unicorn/unicornTracker";
import PressRelease from "./pages/PressRelease/PressRelease";
import Event from "./pages/events/Events";

function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to={"/"} />} />
        <Route path="/" element={<Home />} />
        <Route path="/products/cfs" element={<CFS />} />
        <Route path="/products/mergers-acquisitions" element={<MA />} />
        <Route path="/products/funding" element={<Funding />} />
        <Route path="/leagues" element={<Leagues />}/>
        <Route path="/Indian-union-tracker" element={<UnicornTracker />} />
        <Route path="/Press-Release" element={<PressRelease />} />
        <Route path="/Events" element={<Event />} /> 
      </Routes>
    </>
  );
}

export default App;
