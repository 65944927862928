import React from 'react'
import Layout from '../../components/layout'

const Events = () => {
    return (
        <Layout>
            <div className="flex-col mx-12">
                <div className='my-[22px] text-center mb-20'>
                    <h1 className='text-5xl font-medium text-[#a07924] mb-16'>Events</h1>
                    <h3 className='text-2xl font-medium text-[#475569] '>Venture Intelligence hosts premier events for the Private Equity-Entrepreneur Ecosystem. Our topical, focused and well attended events offer you an excellent platform for gaining knowledge as well as developing your network of contacts.</h3>
                </div>
                <div className='lg:mx-12 md:mx-10 mx-2 xl:mx-12 2xl:mx-12  flex-col  my-8'>
                    <h2 className='text-2xl font-semibold text-black  mb-6'>Upcoming Event:</h2>
                    <div className='ml-2'>
                        <p className='mb-6 text-base text-gray-700'><a href="https://www.ventureintelligence.com/apex24.html" className='underline hover:text-blue-500'>APEX'24 PE-VC Summit & Awards; Mumbai ; Feb 8</a></p>
                        <p className='text-gray-700 text-base'>For discussing potential speaking and sponsorship opportunities, please <a href='mailto:bizdev@ventureintelligence.com?subject=Enquire&body=" "' className='text-sky-800 underline'> Contact Us</a> (Contact Person: Ashok)</p>
                    </div>
                </div>
                <div className='lg:mx-12 md:mx-10 mx-2 xl:mx-12 2xl:mx-12    flex-col  my-8'>
                    <h2 className='text-2xl font-semibold text-black  mb-6'>Recent Events:</h2>
                    <div className='ml-2'>
                        <p className='mb-6 text-base text-gray-700'>On October 11, 2023, Venture Intelligence organized the PE-VC Conclave at Delhi-NCR. <a href='https://ventureintelligence.com/conclave23-d.html' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On July 13, 2023, Venture Intelligence organized the PE/VC Conclave at Bengaluru. <a href='https://ventureintelligence.com/conclave23-b.html' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On Feb 15, 2023, Venture Intelligence organized its flagship APEX PE-VC Summit & Awards at Mumbai. <a href='https://www.ventureintelligence.com/apex23.htm' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On Nov 3, 2022, Venture Intelligence organized the PE-VC Conclave at Mumbai. <a href='https://www.ventureintelligence.com/conclave22-m.htm' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On Sep 6, 2022, Venture Intelligence organized the PE/VC Conclave at Bengaluru. <a href='https://www.ventureintelligence.com/conclave22-b.html' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On February 24, 2022, Venture Intelligence organized an online interaction (webinar) with Jeffrey Zirlin, Co-Founder & Growth Lead, Sky Mavis / Axie Infinity</p>
                        <p className='mb-6 text-base text-gray-700'>On Feb 27, 2019, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href='https://www.ventureintelligence.com/apex19.html' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On September 11, 2018, Venture Intelligence partnered MFIN to organize the 5th Edition of the Microfinance Investment Conclave at Mumbai </p>
                        <p className='mb-6 text-base text-gray-700'>On Feb 28, 2018, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href='https://www.ventureintelligence.com/apex18.htm' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On Feb 22, 2017, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href='https://www.ventureintelligence.com/apex17.htm' className='hover:text-orange-400 underline'>Click Here</a> for the highlights.</p>
                        <p className='mb-6 text-base text-gray-700'>On October 20, 2016, Venture Intelligence helped SIDBI organize the Launch of the <a href="http://venturefund.sidbi.in/?q=content/aspire-fund-0" className='hover:text-orange-400 underline'>Aspire Fund of Funds </a> by the Hon. Minister of Micro, Small & Medium Enterprises, Government of India</p>
                        <p className='mb-6 text-base text-gray-700'>On March 9, 2016, Venture Intelligence organized the Special 10th Edition of the<a href="https://www.ventureintelligence.com/apex16.htm" className='hover:text-orange-400 underline'> APEX Private Equity - Venture Capital Summit </a> in Bangalore</p>
                        <p className='mb-6 text-base text-gray-700'>On February 15, 2016, Venture Intelligence helped CanBank Ventures organize the<a href="http://ventureintelligence.blogspot.in/2016/02/edf-to-back-funds-from-kitven-exfinity.html" className='hover:text-orange-400 underline'>Launch of the Electronics Development Fund (EDF)  </a>by the Hon. Minister of Communications & IT, Government of India</p>
                        <p className='mb-6 text-base text-gray-700'>On November 5, 2015, Venture Intelligence helped CanBank Ventures organize the<a href="http://ventureintelligence.blogspot.in/2015/11/canbank-venture-capital-fund-to-manage.html" className='hover:text-orange-400 underline'>Symposium on Electronics Development Fund (EDF) </a>at Mumbai to discuss the fund-of-funds initiative of the Department of Electronics & Information Technology (DeitY), Ministry of Communications & IT </p>
                    </div>
                </div>

                <div className='lg:mx-12 md:mx-10 mx-2 xl:mx-12 2xl:mx-12    flex-col mt-14 my-10'>
                    <h2 className='text-2xl font-semibold text-amber-700  mb-6'>Why attend Venture Intelligence Events?</h2>
                    <div className='ml-2'>
                        <div>
                            <h2 className='text-lg font-semibold mb-4'>1. The Content</h2>
                            <p className='ml-6'>With several publications dedicated to private equity, Venture Intelligence is highly focused and knows its market intimately. We are uniquely placed to develop events that are highly relevant and address the right topics at the right time.</p>
                        </div>
                        <div className='my-4'>
                            <h2 className='text-lg font-semibold mb-4'>2. The Speakers</h2>
                            <p className='ml-6'>Venture Intelligence events enjoy the enthusiastic support of the most significant players in this industry. Delegates at our events get to meet and hear from leaders who are shaping the PE/M&A industry.</p>
                        </div>
                        <div className='my-4'>
                            <h2 className='text-lg font-semibold mb-4'>3. The Networking</h2>
                            <p className='ml-6'>We work hard to ensure our events provide the best value to attendees - not just content, but also networking.</p>

                            <p className='ml-6 mt-8'><a href="https://www.ventureintelligence.com/past-event.htm" className='hover:text-orange-500 underline'>Click Here</a> to learn about past Venture Intelligence events.</p>
                        </div>
                        <div className='my-4'>
                            <h2 className='text-lg font-semibold mb-4'>Partnership for Custom Events</h2>
                            <p className='ml-6 mt-8'>Would you like to organize an event targeting participation from PE/VC Firms, Entrepreneurs, Investment Bankers and related players in the transactions ecosystem? Venture Intelligence. leveraging its experience organizing such events since 2006, is your ideal partner for this purpose. Contact Us for more details.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Events
