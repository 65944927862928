import React, { useState , useEffect } from "react";
import Table from "./Table";
import Summary from "./Summary";
import Layout from "../../components/layout/index";
import './league.css'
import CategorySelector from "./CategorySelector";




const   League = () => {
  const [category, setCategory] = useState("t");
  const [subCategory, setSubCategory] = useState("pe");
  const fromDate = "2013-01-01"
  const toDate = '2023-03-31';
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [sortedData , setSortedData] = useState(tableData);


  const toggleMainTableHandler = (mainTable) => {
    setCategory(mainTable);
  };
  
  const toggleSubTableHandler = ( subTable) => {
    setSubCategory(subTable);
  };
  // Use useEffect to update sortedData when tableData changes
  useEffect(() => {
    setSortedData(tableData);
  }, [tableData]);



  return (
    <Layout>
      <div className="text-center">
        <h1 className="h-[10rem] flex justify-center ml-4 items-center text-xl text-black md:h-[15rem] md:text-5xl">
          LEAGUE TABLES
        </h1>
      </div>
      <Summary fromDate={fromDate} toDate={toDate} />
        <div className="font-sans text-black ">
          <div className="lg:mx-32 md:mx-2 ">

            <CategorySelector 
              category={category}
              toggleMainTableHandler={toggleMainTableHandler}
              toggleSubTableHandler={toggleSubTableHandler}
              subCategory={subCategory}
              setSubCategory={setSubCategory}
              fromDate={fromDate}
              toDate={toDate}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              setTableData={setTableData}
              setLoading={setLoading}
              showSearchForm={showSearchForm}
              setShowSearchForm={setShowSearchForm}
              sortedData={sortedData}
            />
          </div>
          
          <Table loading={loading} subCategory={subCategory} sortedData={sortedData} setSortedData={setSortedData}  tableData={tableData} setShowSearchForm={setShowSearchForm} showSearchForm={showSearchForm} />
        </div>
    </Layout>
  );
};

export default League;
