    // SearchContent.js
    import React from "react";
    import SearchForm from "../../components/league components/SearchForm";
    import SearchIcon from "../../components/SearchIconComponents/SearchIcon";

    const SearchContent = ({
    searchHandler,
    isChecked,
    setIsChecked,
    category,
    onIncludeExcludeChange,
    onYearChange,
    onIndustryChange,
    resetDropdowns,
    SetResetDropdowns,
    handleSearchIconClick,
    isMobile,
    SetSearching
    }) => {
    return (
        <div className="flex ">
            <SearchForm
                searchHandler={searchHandler}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                category={category}
                onIncludeExcludeChange={onIncludeExcludeChange}
                onYearChange={onYearChange}
                onIndustryChange={onIndustryChange}
                resetDropdowns={resetDropdowns}
                SetResetDropdowns={SetResetDropdowns}
            />


            <button onClick={handleSearchIconClick}>
            <SearchIcon  />
            </button>

        </div>
    );
    };

    export default SearchContent;
