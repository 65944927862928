import React from "react";
import duffel from "../../assets/customers/Duffel 1-2.png";
import taxdoo from "../../assets/customers/Taxdoo 1-2.png";
import perfect from "../../assets/customers/perfect.png";
import incident from "../../assets/customers/incident.png";
import raycast from "../../assets/customers/raycast.png";
import moonpay from "../../assets/customers/moonpay.png";
import phillips from "../../assets/customers/phillips.png";
import kandji from "../../assets/customers/kandji.png";
import wagmo from "../../assets/customers/wagmo.png";

const Customers = () => {
  const customers_details = [
    {
      name: "duffel",
      logo: duffel,
    },
    {
      name: "taxdoo",
      logo: taxdoo,
    },
    {
      name: "perfect",
      logo: perfect,
    },
    {
      name: "incident",
      logo: incident,
    },
    {
      name: "raycast",
      logo: raycast,
    },
    {
      name: "moonpay",
      logo: moonpay,
    },
    {
      name: "phillips",
      logo: phillips,
    },
    {
      name: "kandji",
      logo: kandji,
    },
    {
      name: "wagmo",
      logo: wagmo,
    },
  ];
  return (
    <div className="mt-12 xl:w-full">
      <p>Trusted by founders, cheif-of-staff, biz ops, and finance teams:</p>
      <div className="mt-4 flex items-center justify-center flex-wrap gap-12">
        {customers_details.map((customer) => (
          <img
            src={customer.logo}
            alt={customer.name}
            key={customer.name}
            className="w-20 xl:w-24"
          />
        ))}
      </div>
    </div>
  );
};

export default Customers;
