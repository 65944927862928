import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faClock,
  faCube,
  faDatabase,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

const SubFeatures = () => {
  const subFeatures = [
    {
      title: "Research",
      description: "Investment insights from non-traditional sources.",
      icon: <FontAwesomeIcon icon={faDatabase} size="xl" />,
    },
    {
      title: "Event-driven feeds",
      description:
        "Real-time, machine-readable data for black box applications.",
      icon: <FontAwesomeIcon icon={faClock} size="xl" />,
    },
    {
      title: "Market data",
      description: "The most comprehensive real-time market data available.",
      icon: <FontAwesomeIcon icon={faCube} size="xl" />,
    },
    {
      title: "Pricing data",
      description: "Exchange and contributed pricing across every asset class.",
      icon: <FontAwesomeIcon icon={faDollarSign} size="xl" />,
    },
    {
      title: "Reference data",
      description:
        "Fundamentals and estimates, corporate actions, legal entity data and more.",
      icon: <FontAwesomeIcon icon={faBullseye} size="xl" />,
    },
    {
      title: "Regulatory data",
      description:
        "Solutions that satisfy current regulations and help firms prepare for new rules.",
      icon: <FontAwesomeIcon icon={faDollarSign} size="xl" />,
    },
  ];
  return (
    <div className="px-4 py-10 bg-gray-800  text-white ">
      <div className="xl:w-[1216px] mx-auto grid gap-4 lg:grid-cols-3">
        {subFeatures.map((feature) => (
          <div key={feature.title} className="m-2">
            <h1 className="flex justify-start align-center">
              {feature.icon}{" "}
              <span className="font-bold ml-3 mb-2">{feature.title}</span>
            </h1>
            <p className="text-gray-400 pl-1">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubFeatures;
