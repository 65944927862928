import React from "react";
import Container from "../../../components/layout/Container";
import Button from "../../../components/utils/Button";
import hero from "../../..//assets/m&aHero.png";
import Customers from "../../../components/common/Customers";

const MAHeroSec = () => {
  return (
    <div>
      <Container>
        <div className="text-center">
          <h1 className="my-4 text-gray-600 text-3xl leading-snug tracking-tighter font-bold md:text-4xl">
            Crunchbase Starter:
          </h1>
          <h1 className="my-4 text-4xl leading-snug tracking-tighter font-bold md:text-4xl">
            Track and Monitor the Companies You Care About
          </h1>
          <h2 className="text-gray-900 font-semibolds text-lg">
            Make more informed business decisions with Crunchbase’s
            best-in-class data and actionable insights.
          </h2>
          <Button className="my-8 px-8">Try Now</Button>
        </div>
        <div>
          <img src={hero} alt="" />
        </div>
        <Customers />
      </Container>
    </div>
  );
};

export default MAHeroSec;
