import React from "react";

import grid from "../../assets/backgrounds/sub_grid.png";
import inscribe from "../../assets/reviews/inscribe.png";
import vector from "../../assets/reviews/Vector.png";
import group from "../../assets/reviews/Group 28.png";
import Container from "../../components/layout/Container";
import Button from "../../components/utils/Button";

const Review = () => {
  return (
    <div className="bg-blue-50">
      <Container className="flex flex-col-reverse gap-12 lg:py-20 lg:flex-row lg:justify-center lg:gap-4">
        <div className="h-[700px] sm:max-h-[480px] lg:w-[45%]">
          <div
            style={{ backgroundImage: `url(${grid})` }}
            className="m-2 p-12 pb-24 relative bg-blue-500 text-white rounded-t-3xl"
          >
            <img src={inscribe} alt="" className="w-32" />
            <p className="pt-4 text-xs font-semibold">27 EMPLOYEES, SERIES A</p>
            <div className="absolute top-32 md:w-10/12 left-0 md:left-12">
              <div className="mx-2 p-8 bg-gray-800 text-white text-lg rounded-2xl">
                “Causal has let me automate our routine finance processes to
                give me a lot more leverage in my role, and the Causal
                dashboards have increased transparency and alignment throughout
                the company.”
              </div>
              <img src={vector} alt="" className="ml-10" />
              <div className="mt-4 flex gap-4 items-center">
                <img src={group} alt="" width={"55px"} />
                <div>
                  <p className="mb-2 text-gray-800 text-xs">AIDAN DAVIES</p>
                  <p className="text-gray-700 text-xs font-bold">
                    CHEIF OF STAFF
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start gap-8 lg:w-[45%]">
          <div>
            <h3 className="m-4 text-xs text-blue-500 font-semibold">
              CASE STUDY
            </h3>
            <h1 className="m-4 text-gray-800 font-bold text-2xl md:text-3xl lg:w-3/5">
              How Causal gives leverage to a busy chief-of-staff
            </h1>
            <p className="mx-4 text-base text-gray-600">
              Before Causal, Aidan was spending countless hours every month on
              routine finance processes and answering requests from the company
              — all while doing 5 other jobs.
            </p>
            <div className="my-4"></div>
            <p className="mx-4 text-base text-gray-600">
              With Causal, the Inscribe team can access targets and metrics
              self-serve, and Aidan can focus on helping the business make
              better decisions.
            </p>
          </div>
          <Button>Read Case Study</Button>
        </div>
      </Container>
    </div>
  );
};

export default Review;
