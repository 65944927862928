import React from "react";

import GPVsE from "../../assets/Gross Profit vs Expenses.png";
import Container from "../layout/Container";
import Button from "../utils/Button";

const Visualisation = () => {
  return (
    <div className="px-4 bg-white">
      <Container className="mt-8 p-4 flex flex-col gap-8 lg:flex-row">
        <div className="mx-auto px-4 pt-8 bg-gray-50">
          <img
            src={GPVsE}
            alt=""
            className="shadow-2xl rounded-xl md:max-w-lg"
          />
        </div>
        <div className="mx-auto flex flex-col gap-3 items-start md:max-w-lg">
          <h3 className="my-4 text-xs text-blue-500  font-semibold">
            DATA VISUALISATION
          </h3>
          <div>
            <h1 className="my-4 text-gray-800 font-bold text-3xl lg:w-3/5">
              Share progress with your team and investors
            </h1>
            <p className="text-base text-gray-600">
              Put reporting on autopilot with Causal's live dashboards — let
              your team and investors see your numbers and play with key
              assumptions, without breaking your model.
            </p>
            <br />
            <p className="text-base text-gray-600">
              Causal gives you beautiful charts and tables out of the box, and
              lets you slice-and-dice to create the views you need.
            </p>
          </div>
          <Button>See Live Dashboard</Button>
        </div>
      </Container>
    </div>
  );
};

export default Visualisation;
